/*
* personeel_afwezigheid.ts
* 1-7-2024 - Jelmer Jellema - Spin in het Web B.V.
*
* Afwezigheid zoals door personeel opgegeven
*/

import {Basemodel, ModelField} from './basemodel';
import * as moment from 'moment/moment';

export class PersoneelAfwezigheid extends Basemodel {
  static table = 'personeelvast_afwezigheid';
  // static loglevel: string = 'debug';
  @ModelField() id: number;
  @ModelField() personeelvast_id: number;
  //het is of een begin en einddatum en dan hele dagen, of alleen een startdatum en dan een begintijd en eindtijd
  @ModelField() startdatum: string; //yyyy-mm-dd
  @ModelField() einddatum: string | null = null;
  @ModelField() begintijd: string | null = null; //null betekent: hele dag
  @ModelField() eindtijd: string | null = null; //00:00 is einde dag

  /**
   * True als de afwezigheid over 1 of meerdere hele dagen gaat
   */
  get isHeledagen(): boolean {
    return !this.begintijd;
  }

  /**
   * Geeft iets van op Datum van X tot Y, of van Datum x tot Datum y
   */
  get vanTotString(): string {
    if (this.isHeledagen) {
      if (this.startdatum === this.einddatum) {
        return 'op ' + moment(this.startdatum).format('D-M-YY');
      } else {
        return `van ${moment(this.startdatum).format('D-M-YY')} t/m ${moment(this.einddatum).format('D-M-YYY')}`;
      }
    } else {
      if (this.eindtijd === '00:00') {
        return `op ${moment(this.startdatum).format('D-M-YY')} vanaf ${this.begintijd}`;
      } else if (this.eindtijd > this.begintijd) {
        return `op ${moment(this.startdatum).format('D-M-YY')} van ${this.begintijd} tot ${this.eindtijd}`;
      } else {
        return `van ${moment(this.beginmoment()).format('D-M-YY HH:mm')} tot ${this.eindmoment().format('D-M-YY HH:mm')}`;
      }
    }
  }

  beginmoment(): moment.Moment {
    return moment(`${this.startdatum} ${this.begintijd ? this.begintijd : '00:00'}`, 'YYYY-MM-DD HH:mm');
  }

  eindmoment(): moment.Moment {
    let m = moment(`${this.einddatum ? this.einddatum : this.startdatum} ${this.isHeledagen ? '00:00' : this.eindtijd}`, 'YYYY-MM-DD HH:mm');

    //bij hele dagen of als de eindtijd 00:00 (dan is het voor de begintijd) is of voor de begintijd valt: dan is het de volgende dag
    if (this.isHeledagen || this.eindtijd <= this.begintijd) {
      m.add(1, 'day');
    }
    return m;
  }

  /**
   * Helper: geeft true als deze afwezigheid iets zegt over de opgegeven datum
   * @param datestr
   * @param begintijd als gegeven, gaat het om de afwezigheid tussen begin- en eindtijd
   * @param eindtijd
   */
  afwezigOp(datestr: string, begintijd?: string, eindtijd?: string): boolean {
    //deze afwezigheid gaat over de opgeven datum als de startdatum dat zegt, of de datum tussen start en einddatum valt, of het een eenvoudige afwezigheid binnen een dag is, maar de eindtijd is op de datestr

    //situatie 0: missende gegevens
    if (!this.startdatum) {
      return false;
    }

   let gebruikbegintijd = begintijd?.length ? begintijd : '00:00';
    let gebruikeindtijd = eindtijd?.length ? eindtijd : '00:00';

    let begincheck: moment.Moment = moment(`${datestr} ${gebruikbegintijd}`, 'YYYY-MM-DD HH:mm');
    let eindcheck: moment.Moment = moment(`${datestr} ${gebruikeindtijd}`, 'YYYY-MM-DD HH:mm');

    //als er geen eindtijd was, dan geldt dat als de hele dag
    //en als eindcheck op of voor begincheck ligt, dan is het ook de volgende dag:

    if ((! eindtijd?.length) || eindcheck.isSameOrBefore(begincheck)) {
      eindcheck.add(1, 'day'); //dan is de het de volgende dag
    }

    //is er overlap?
    /*    return begincheck.isBefore(this.eindmoment()) && eindcheck.isAfter(this.beginmoment());*/
    return begincheck.isBefore(this.eindmoment()) && eindcheck.isAfter(this.beginmoment());
  }
}
