/**
 * totaalexport.ts
 * 20-05-2024 - Jelmer Jellema - Spin in het Web B.V. Apeldoorn NL
 * Component Totaalexportscherm
 * Selector: totaalexport
 */
import {Component, Injector} from '@angular/core';
import {Appscherm} from '../abstracts/appscherm';
import {Planitem} from '../../models/planitem';
import {PersoneelPlanitem} from '../../models/personeel_planitem';
import {PlanitemFunction} from '../../models/planitem_function';
import {Functie} from '../../models/functie';
import {hash} from '../../app/types';
import {Personeel} from '../../models/personeel';
import {Project} from '../../models/project';
import {Leverancier} from '../../models/leverancier';
import {Werkmaatschappij} from '../../models/werkmaatschappij';
import {sortBy} from 'lodash-es';
import * as Excel from 'exceljs';
import {FileSaverService} from 'ngx-filesaver';
import * as moment from 'moment';
import {Werkbon} from '../../models/werkbon';

@Component({
  selector: 'totaalexport',
  templateUrl: './totaalexport.html',
  styleUrls: ['totaalexport.scss']
})
export class Totaalexportscherm extends Appscherm {
  _starttitle = 'Totaalexport';
  icon = 'fas fa-file-export';
  fixedHeight = 250;
  fixedWidth = 300;
  fixedSize = true;

  constructor(inject: Injector, private filesaver: FileSaverService) {
    super(inject, 'debug');
  }

  /**
   * De export-functie
   */
  async exporteer() {

    //opzet excel
    const workbook = new Excel.Workbook;
    workbook.creator = 'ATRPlan - Slimme Infrasoftware B.V. Apeldoorn';
    //benodigde extra data halen we op in lookups
    const functies: hash<Functie> = await Functie.getLookup(Planitem);
    const personeel: hash<Personeel> = await Personeel.getLookup();
    const projecten: hash<Project> = await Project.getLookup();
    const leveranciers: hash<Leverancier> = await Leverancier.getLookup(Planitem);
    const werkmaatschappijen: hash<Werkmaatschappij> = await Werkmaatschappij.getLookup();

    //we halen de planitems in stappen op, sorteren doen we daarna wel
    //we maken de 2 sheets gelijktijdig

    let planitems: Planitem[] = [];
    const stappen = 1000;
    let offset = 0;
    let data: Planitem[] = [];
    do {
      data = await Planitem.getAll([PersoneelPlanitem, PlanitemFunction, Werkbon], [
        [Planitem, {limit: stappen, offset: offset}],
        [Werkbon, {
          attributes: ['id', 'status', 'starttijd', 'eindtijd', 'pauze', 'kilometers', 'kilometers_ww', 'opmerkingen', 'proforma', 'ondertekenaar', 'createdAt'],
          required: false
        }]
      ], null, null, null, true, false); //alleen de planitems zelf, zonder cachen
      if (!data) {
        this.log.warn('Geen data');
        break;
      }
      planitems.push(...data);
      offset += stappen;
    } while (data.length === stappen);

    //sorteren
    planitems = sortBy(planitems, ['startdate', 'starttime']);

    //maak de sheets
    const plansheet = workbook.addWorksheet('Diensten', {
      views: [{state: 'frozen', ySplit: 1}]
    });

    plansheet.columns = [
      {header: 'Week', width: 5},
      {header: 'Datum', width: 11},
      {header: 'Begin', width: 7},
      {header: 'Eind', width: 7},
      {header: 'WM', width: 10},
      {header: 'Klant', width: 20},
      {header: 'Project', width: 15},
      {header: 'Projectomschrijving', width: 40},
      {header: 'Capaciteit', width: 10},
      {header: 'Functie', width: 30},
      {header: 'Alias', width: 40},
      {header: 'Extra functies', key: 'extrafuncties', width: 40},
      {header: 'Invullende leverancier', key: 'leverancier', width: 40},
      {header: 'Personeel', key: 'personeel', width: 40},
      {header: 'Opmerkingen', key: 'opmerkingen', width: 80},
      {header: 'Aanvraagnummer', width: 15}
    ];

    //eerste rij is bold
    plansheet.getRow(0).font = {bold: true};
    plansheet.getRow(0).alignment = {horizontal: 'center'};
    //en sommige kolommen hebben nog iets speciaals:
    plansheet.getColumn('extrafuncties').alignment = {wrapText: true};
    plansheet.getColumn('personeel').alignment = {wrapText: true};
    plansheet.getColumn('opmerkingen').alignment = {wrapText: true};

    const werksheet = workbook.addWorksheet('Werkbonnen', {
      views: [{state: 'frozen', ySplit: 1}],
    });

    werksheet.columns = [
      {header: 'Week', width: 5},
      {header: 'Datum', width: 11},
      {header: 'Begin', width: 7},
      {header: 'Eind', width: 7},
      {header: 'WM', width: 10},
      {header: 'Klant', width: 25},
      {header: 'Project', width: 15},
      {header: 'Projectomschrijving', width: 40},
      {header: 'Functie', key: 'functie', width: 30},
      {header: 'Status', width: 20},
      {header: 'Aangemaakt', width: 10},
      {header: 'Gestart', width: 7},
      {header: 'Gestopt', width: 7},
      {header: 'KM Project', width: 10},
      {header: 'KM ww', width: 5},
      {header: 'Pauze', width: 5},
      {header: 'Personeel', width: 40},
      {header: 'E-mail',width: 30},
      {header: 'Groep', width: 20},
      {header: 'Leverancier', key:'leverancier', width: 25},
      {header: 'Ondertekenaar', width: 20},
      {header: 'Bestelnr', width: 20},
      {header: 'Ingediend', width: 10},
      {header: 'Opmerkingen', key: 'opmerkingen', width: 40},
    ];
    werksheet.getColumn('functie').alignment = {wrapText: true};
    werksheet.getColumn('leverancier').alignment = {wrapText: true};
    werksheet.getColumn('opmerkingen').alignment = {wrapText: true};

    for (let item of planitems) {
      const project: Project = projecten[item.project_id];
      let functie: Functie;
      if (item.mainFunction) {
        functie = functies[item.mainFunction];
      }
      const extrafuncties = item.extraFunctions.map(fid => functies[fid]?.naam || '?').join(', ');
      item.setPersoneel(personeel);
      item.setFunctionlabel(functies);
      item.setLeveranciers(leveranciers);
      const week = moment(item.startdate).format('YYYY-WW');
      const datum = new Date(item.startdate);
      plansheet.addRow([
        week,
        datum,
        item.starttime,
        item.endtime,
        project ? werkmaatschappijen[project.werkmaatschappij_id]?.afkorting : '',
        project?.klantnaam,
        project?.naam,
        project?.omschrijving,
        item.capacity,
        functie?.naam,
        item.functionalias,
        extrafuncties,
        item.leverancier_id ? leveranciers[item.leverancier_id]?.naam : null,
        item.personeel.map(p => p?.naam || '?').join('\r'),
        item.opmerkingen,
        item.aanvraagnummer
      ]);
      for (let ppi of item.personeel_planitem) {
        let status: string;
        if (! ppi.werkbon)
        {
          status = 'Ontbreekt';
        }
        else if (ppi.werkbon.status === 'ingediend')
        {
          status = ppi.werkbon.proforma ? 'Ongetekend' : 'Getekend';
        }
        else {
          status = ppi.werkbon.status;
        }
        werksheet.addRow([
          week,
          datum,
          item.starttime,
          item.endtime,
          project ? werkmaatschappijen[project.werkmaatschappij_id]?.afkorting : '',
          project?.klantnaam,
          project?.naam,
          project?.omschrijving,
          item.functionlabel,
          status,
          ppi.werkbon ? new Date(ppi.werkbon.createdAt) : "",
          ppi.werkbon?.starttijd,
          ppi.werkbon?.eindtijd,
          ppi.werkbon?.kilometers,
          ppi.werkbon?.kilometers_ww,
          ppi.werkbon?.pauze,
          ppi.personeel?.naam,
          ppi.personeel?.email,
          ppi.personeel?.groep,
          ppi.personeel?.groep !== 'Personeel' ? ppi.leverancier?.naam : "",
          ppi.werkbon?.ondertekenaar,
          ppi.inkooporder,
          ppi.ingediend,
          ppi.werkbon?.opmerkingen
        ]);
      }
    }

    ////// opslaan excel
    const buffer = await workbook.xlsx.writeBuffer();
    this.filesaver.save(new Blob([buffer]), `ATRPlan-Totaalexport ${moment().format('YYYY-MM-DD')}.xlsx`);
    this.api.toast('Het Excel-bestand is door je browser gedownload. Je vindt het rechtsboven of in je downloadmap.', 'success');
  }
}
