/*
* werkbon.ts
* 1-3-2023 - Jelmer Jellema - Spin in het Web B.V.
* Model voor werkbon-tabel: ondertekenen van uitgevoerde diensten
*/

import {Basemodel, ModelField} from "./basemodel";
import {PersoneelPlanitem} from './personeel_planitem';
import {Planitem} from './planitem';
import * as moment from "moment";

export class Werkbon extends Basemodel {
  static override table = `werkbon`;

  //eigenaar is PersoneelPlanitem
  @ModelField() id: number;
  @ModelField() personeelvast_planitem_id: number;
  @ModelField() status: 'ingediend' | 'geaccepteerd' | 'gefactureerd' = 'ingediend';
  @ModelField() starttijd: string;
  @ModelField() eindtijd: string;
  @ModelField() overrule_starttijd: string | null = null;
  @ModelField() overrule_eindtijd: string | null = null;
  @ModelField() pauze: number = 0; //minuten
  @ModelField() kilometers: number = 0; //INT, projectkm's
  @ModelField() kilometers_ww: number = 0; //INT, woonwerk-km's
  @ModelField() opmerkingen: string = '';
  @ModelField() proforma: boolean = false; //proforma = niet ondertekend
  @ModelField() ondertekenaar: string = null;
  @ModelField() handtekening: string = null; //data-url voor png
  @ModelField() extradocument: string = null; //en de dataurl voor een extra foto
  @ModelField() createdAt: string = null;

  /**
   * cast de owner
   */
  get personeelPlanitem(): PersoneelPlanitem
  {
    return (<PersoneelPlanitem> this._owner);
  }

  /**
   * Geef de bijbehorende planitem terug, als die er is
   */
  get planitem(): Planitem {
    return this.personeelPlanitem?.planitem;
  }

  get gebruik_starttijd(): string {
    return this.overrule_starttijd ?? this.starttijd;
  }

  get gebruik_eindtijd(): string {
    return this.overrule_eindtijd ?? this.eindtijd;
  }
  /**
   * geeft true als de tijden anders zijn dan owner
   */
  get afwijkend(): boolean {
    return this.planitem &&
      (
        this.planitem.starttime !== this.gebruik_starttijd ||
        this.planitem.endtime !== this.gebruik_eindtijd
      );
  }

  get starttijd_overruled(): boolean {
    return !! this.overrule_starttijd
  }

  get eindtijd_overruled(): boolean {
    return !! this.overrule_eindtijd;
  }

  /**
   * Geef het aantal werkuren op deze werkbon, of false als het niet te bepalen is
   */
  get werkuren(): number | false {
    if (! (this.gebruik_starttijd && this.gebruik_eindtijd))
    {
      return false;
    }
    //als we geen datum uit het planitem kunnen halen dan doen we het op vandaag
    let datum = this.planitem? this.planitem.startdate : moment().format('YYYY-MM-DD');
    const eindm = moment(`${datum} ${this.gebruik_eindtijd}` );
    //we trekken de pauze er op het einde af
    eindm.subtract({minute: this.pauze || 0});
    const startm = moment(`${datum} ${this.gebruik_starttijd}` );
    return eindm.diff(startm,"hour",true);
  }
}
