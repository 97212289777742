/*
* mdatepipe.ts
* 24-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Pipe, PipeTransform} from "@angular/core";
import moment from 'moment';

@Pipe({ name: 'mdate'})
export class Mdatepipe implements PipeTransform {
  transform(value?: moment.MomentInput, format: string = 'DD-MM-YYYY' ): string | null {
    if (! value)
    {
      return null;
    }
    return moment(value).format(format);
  }
}
